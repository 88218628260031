exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-grantee-list-37-dg-56-mgw-3-n-6-lyeanf-6-mvf-js": () => import("./../../../src/pages/grantee-list-37dg56mgw3n6lyeanf6-mvf.js" /* webpackChunkName: "component---src-pages-grantee-list-37-dg-56-mgw-3-n-6-lyeanf-6-mvf-js" */),
  "component---src-pages-healingjustice-js": () => import("./../../../src/pages/healingjustice.js" /* webpackChunkName: "component---src-pages-healingjustice-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-visionintopower-js": () => import("./../../../src/pages/visionintopower.js" /* webpackChunkName: "component---src-pages-visionintopower-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basicPage.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-post-category-page-js": () => import("./../../../src/templates/postCategoryPage.js" /* webpackChunkName: "component---src-templates-post-category-page-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/postPage.js" /* webpackChunkName: "component---src-templates-post-page-js" */)
}

